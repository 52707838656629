import * as React from "react"
import { StoreProvider } from "./src/context/store-context"
import "./src/styles/reset.css"
import "./src/styles/vendor.css"
import "./src/styles/global.css"

export const wrapRootElement = ({ element }) => (
  <StoreProvider>{element}</StoreProvider>
)

const addScript = url => {
  const script = document.createElement("script")
  script.src = url
  script.async = true
  document.body.appendChild(script)
}
export const onClientEntry = () => {
  window.onload = () => {
    addScript("https://scripts.juniphq.com/v1/junip_shopify.js")
    addScript("//static.klaviyo.com/onsite/js/klaviyo.js?company_id=Wn6APa")
  }
}